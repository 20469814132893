import React, { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import IframeResizer from "iframe-resizer-react";
import { Box } from "@mui/material";
import { useFetchLandingPageSettings } from "./hooks/api/fetch";
const EventLandingPage = lazy(() =>
  import("../../pages/events2.0/pages/events/Events")
);
const Register2 = lazy(() =>
  import("../../pages/events2.0/pages/register/Register")
);
const Login2 = lazy(() => import("../../pages/events2.0/pages/login/Login"));
const Homepage2 = lazy(() =>
  import("../../pages/events2.0/pages/homepage/Homepage")
);
const Leaderboard2 = lazy(() =>
  import("../../pages/events2.0/pages/Leaderboard/NewLeaderboard")
);
const Logout2 = lazy(() => import("../../pages/events2.0/pages/logout/Logout"));
const Gameplay2 = lazy(() =>
  import("../../pages/events2.0/pages/gameplay/Gameplay")
);
const Profile2 = lazy(() =>
  import("../../pages/events2.0/pages/profile/Profile")
);
const Onboarding = lazy(() => import("./pages/Onboarding/Onboarding"));
const Activities = lazy(() => import("./pages/Activities/Activities"));
const Explore = lazy(() => import("./pages/explore/Explore"));
const Notifications = lazy(() => import("./pages/notification/Notifications"));
const Feedback = lazy(() => import("./pages/feedback/Feedback"));
const QuizPlay = lazy(() => import("./pages/quizPlay/QuizPlay"));
const QuizCompleted = lazy(() => import("./pages/quizCompleted/QuizCompleted"));
const HowToPlay = lazy(() => import("./pages/howToPlay/HowToPlay.jsx"));

function Events() {
  const [gamePreloader, setGamePreloader] = useState(true);
  const id = useParams()["*"];

  return (
    <Suspense fallback="Loading">
      <Routes>
        <Route path="/:id" element={<EventLandingPage />} />
        <Route
          path="/:id/register"
          element={<Register2 setGamePreloader={setGamePreloader} />}
        />
        <Route
          path="/:id/login"
          element={<Login2 setGamePreloader={setGamePreloader} />}
        />
        <Route path="/:id/homepage" element={<Onboarding />} />
        <Route path="/:id/quizplay" element={<QuizPlay />} />
        <Route path="/:id/help" element={<HowToPlay />} />
        <Route path="/:id/gameplay" element={<Gameplay2 />} />
        <Route path="/:id/quizcompleted" element={<QuizCompleted />} />
        <Route path="/:id/leaderboard" element={<Leaderboard2 />} />
        <Route path="/:id/logout" element={<Logout2 />} />
        <Route path="/:id/profile" element={<Profile2 />} />
        <Route path="/:id/onboarding" element={<Onboarding />} />
        <Route path="/:id/activities" element={<Activities />} />
        <Route path="/:id/explore" element={<Explore />} />
        <Route path="/:id/notifications" element={<Notifications />} />
        <Route path="/:id/feedback" element={<Feedback />} />{" "}
      </Routes>
      {!window.location.href.includes("/gameplay") ? <Box></Box> : null}
      {/* <GamePreloader id={id} /> */}
    </Suspense>
  );
}
function GamePreloader({ id }) {
  const [searchParams] = useSearchParams();
  const quiz_id = searchParams.get("id");
  let event_code = id.includes("/") ? id.split("/")?.[0] : id;
  const landingPageAPI = useFetchLandingPageSettings(event_code);
  const gameList = sessionStorage.getItem("gameList");
  const [games, setGames] = useState([]);
  useEffect(() => {
    if (!!gameList) {
      let newGames = JSON.parse(gameList);
      setGames(newGames);
    }
  }, [gameList]);

  console.log({ landingPageAPI, gameList, data: landingPageAPI.data?.quizUrl });
  return (
    <Box
      sx={
        {
          // visibility: "hidden",
          // position: "absolute",
        }
      }
    >
      {/* {landingPageAPI.data?.quizUrl?.map((item, index) => ( */}
      {games?.map((item, index) => (
        <>
          <IframeResizer
            // style={{ height: 1, width: 1 }}
            key={index}
            src={item?.quizUrl}
            style={{
              position: "absolute",
              width: "100%",
              zIndex:
                item?.id === quiz_id &&
                window.location.href.includes("gameplay")
                  ? -4
                  : -4,
              visibility:
                item?.id === quiz_id &&
                window.location.href.includes("gameplay")
                  ? "hidden"
                  : "hidden",

              height: "10vh",
              paddingBottom: "80px",
              // top: 0,
              // left: 0,
              right: 0,
              bottom: 30,
            }}
            // src="https://qwizmobi.s3.amazonaws.com/quizzies/GrowThatMoney/index.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQ2XSIUXYNZYMFV4Q%2F20240301%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240301T161219Z&X-Amz-Expires=3600&X-Amz-Signature=7675fd4236f8866df3650a073d1b5fd0999ff37eb2094ba27ba86c2a8538e685&X-Amz-SignedHeaders=host&age_group=null&display_name=gest&game_id=z701afa4-d598-436e-9316-5e1474a2d462&home_url=https%3A%2F%2Fqwizfun.com%2Fevent%2F421296%2Fleaderboard&leaderboard_url=https%3A%2F%2Fqwizfun.com%2Fevent%2F421296%2Fhomepage&quiz_id=z701afa4-d598-436e-9316-5e1474a2d462&user_id=b9af877c-bfe8-4664-b978-d210edc762ef"
          />
        </>
      ))}{" "}
    </Box>
  );
}

export default Events;
