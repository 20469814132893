import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
// import Leaderboard from "./pages/Leaderboard/Leaderboard";

import Chatwoot from './components/chatwoot/Chatwoot';
import { Box, createTheme, ThemeProvider } from '@mui/material';

import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import Events2 from './pages/events2.0/Events';

function App() {
  console.log("femi new deployment 06/21/2024 17:24")
  const { pathname } = useLocation();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#277BA0',
        dark: '#277BA0',
        light: 'rgba(39, 123, 160, 0.4)',
      },
    },
    typography: {
      fontFamily: "'Poppins', san-serif",
    },
  });

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>

        <Routes>
          {/* 2.0 for customizable */}

 
          <Route path='/event/*' element={<Events2 />} />
          {/* <Route path='/event/683097/*' element={<SteamRedirectFix />} /> */}

          {/* 2.0 for customizable ends */}

      
        </Routes>
      </ThemeProvider>

      <ToastContainer />
      {!pathname.includes('/gameplay') ? (
        <Box>
          <Chatwoot pathname={pathname} />
        </Box>
      ) : null}
    </div>
  );
}

export default App;

function SteamRedirectFix() {
  if (window.location.origin.includes("stemgames")) {
    
    window.location.href="http://game-lb.qwizfun.com/event/683097"
  }
  return <>Redirecting...Please Wait</>

}
